@font-face {
  font-family: fontMontserrat;
  src: url(./styles/fonts//Montserrat-Light.ttf);
}

@font-face {
  font-family: fontMulish;
  src: url(./styles/fonts//Mulish-Light.ttf);
}

@tailwind base;
@tailwind components;
@tailwind utilities;


html {
  height: 100%;
}
body { 
  padding: 0;
  margin: 0;
  font-family: "Mulish", sans-serif;
  overflow-x: hidden;
  min-height: 100%;
}


a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.font-montserrat {
  font-family: "fontMontserrat";
}

.font-mulish {
  font-family: "fontMulish";
}

.title {
  font-size: 28px;
  font-weight: 700;
}

@media only screen and (max-width: 1279px) {
  .title {
    font-size: 24px;
    font-weight: 700;
  }
}

@media only screen and (max-width: 400px) {
  .title {
    font-size: 22px;
    font-weight: 700;
  }
}
