.carousel-arrow {
  background: transparent;
  border    : none;
  cursor    : pointer;
  font-size : 2rem;
  position  : absolute;
  top       : 50%;
  transform : translateY(-50%);
}

.prev {
  left: 0;
}

.next {
  right: 0;
}

.slick-slide {
  padding   : 1rem;
  text-align: center;
}

.slick-slider .slick-list {
  padding-bottom: 40px !important;
}

.slick-prev:before, .slick-next:before {
  opacity: 0 !important;
}

